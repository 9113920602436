var Stories = {

    /**
     }
     * Callback for the Vimeo Loading.
     * Inject into a story.
     * @param video
     */
    loadVideo: function (video) {
        if (video.video_id) {
            var el = jQuery('#video' + video.video_id + ' a.vimeo-popup');
            if (el.parent().has(".card-img-top").length < 1) {
                var category = jQuery(el).parent().data('cat');
                var thumbnail = 'theme_' + category + '_1.jpg';
                var img = jQuery('<img />');
                img.addClass('card-img-top img-fluid img-responsive thumbnail h-down');
                img.attr('src', '/images/stories/placeholders/' + thumbnail);
                img.attr('alt','');
                el.html('<img src="/images/icons/play_icon.svg" alt="" class="img-fluid play-btn">');
                el.parent().append(img);
                el.parent().find("a.link-thru").remove();

                // yield to the browser (so it can draw the img) before updating layout
                setTimeout(function () {
                    jQuery("#my-stories").masonry("layout");
                }, 0);
            }
        }
    },

    /**
     * Loads and initialize Videos for the main story page.
     * Adds thumbnails.
     * @param els jQuery object
     */
    loadingVideos: function (els) {
        els.each(function (i, el) {
            var endpoint = 'https://www.vimeo.com/api/oembed.json';
            var vimeo_url = jQuery(el).data('videoSrc');
            var url = endpoint + '?url=' + encodeURIComponent(vimeo_url) + '&callback=Stories.loadVideo&player_id=<?php echo $story->id; ?>';
            jQuery.getScript(url);
        });

        els.find('a').magnificPopup({
            disableOn: 0,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });

    }
}